import styled from '@emotion/styled'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import React, { memo } from 'react'

export interface Props {
  description?: string
  siteName?: string
  title?: string
}

export const PolicyParagraph = memo(function PolicyParagraph({
  description,
  siteName,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      <IconLogo />

      {siteName ? <SiteName>{siteName}</SiteName> : null}
      {title ? (
        <Title>
          {title}
          <Line />
        </Title>
      ) : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 9.25rem 0 12.5rem;
  padding: 0 10.556vw;

  > svg {
    width: 4.9375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-bottom: 3.25rem;
  }

  @media (max-width: 1199px) {
    max-width: 60.6875rem;
    margin: 8.125rem auto 7.5rem;
    padding: 0 1.875rem;

    > svg {
      width: 3.75rem;
      margin-bottom: 2.25rem;
    }
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const SiteName = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-bottom: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1.25rem;
    }
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin-top: 2.5rem;

  a {
    color: ${({ theme }) => theme.colors.variants.secondaryLight};
    text-decoration: underline;
  }
  p {
    margin-block-end: 1em;
  }
  ul {
    margin-bottom: 1em;
    padding: inherit;
    li {
      padding-left: 0.875rem;
      position: relative;
      &:before {
        content: '';
        width: 0.3125rem;
        height: 0.3125rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark4};
        border-radius: 50%;
        position: absolute;
        top: 0.8125rem;
        left: 0;
      }
    }
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 1.75rem;
  }
`
